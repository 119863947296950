// -- COLORS --
export const COLOR_GREEN = '#00B000';
export const COLOR_BLUE = '#29ABE2';
export const COLOR_YELLOW = '#FCCC21';
export const COLOR_MAGENTA = '#ED1E79';
export const COLOR_BLACK = '#010101';
export const COLOR_WHITE = '#ffffff';
export const COLOR_GREY = '#4B4B4B';
export const COLOR_GREY_LIGHT = '#B7B7B7';
export const COLOR_GREY_DARK = '#171717';

// -- FONT SIZES --
export const FONT_SIZE_XXXXLARGE = '174px';
export const FONT_SIZE_XXXLARGE = '77px';
export const FONT_SIZE_XXLARGE = '72px';
export const FONT_SIZE_XLARGE = '37px';
export const FONT_SIZE_LARGE = '33px';
export const FONT_SIZE_MEDIUM = '24px';
export const FONT_SIZE_SMALL = '20px';
export const FONT_SIZE_XSMALL = '18px';
export const FONT_SIZE_XXSMALL = '16px';
export const FONT_SIZE_XXXSMALL = '14px';

// -- LAYOUT SIZES --
export const LAYOUT_MAX_WIDTH = '1390px';
export const GRID_COLUMNS = 12;

// -- Z-INDEXES --
export const ZINDEX_ONE = 1;
export const ZINDEX_TWO = 2;

// -- EASINGS --
export const LINEAR = 'cubic-bezier(0.250, 0.250, 0.750, 0.750)';
export const EASE = 'cubic-bezier(0.250, 0.100, 0.250, 1.000)';
export const EASE_IN = 'cubic-bezier(0.420, 0.000, 1.000, 1.000)';
export const EASE_OUT = 'cubic-bezier(0.000, 0.000, 0.580, 1.000)';
export const EASE_IN_OUT = 'cubic-bezier(0.420, 0.000, 0.580, 1.000)';
export const EASE_IN_QUAD = 'cubic-bezier(0.550, 0.085, 0.680, 0.530)';
export const EASE_IN_CUBIC = 'cubic-bezier(0.550, 0.055, 0.675, 0.190)';
export const EASE_IN_QUART = 'cubic-bezier(0.895, 0.030, 0.685, 0.220)';
export const EASE_IN_QUINT = 'cubic-bezier(0.755, 0.050, 0.855, 0.060)';
export const EASE_IN_SINE = 'cubic-bezier(0.470, 0.000, 0.745, 0.715)';
export const EASE_IN_EXPO = 'cubic-bezier(0.950, 0.050, 0.795, 0.035)';
export const EASE_IN_CIRC = 'cubic-bezier(0.600, 0.040, 0.980, 0.335)';
export const EASE_IN_BACK = 'cubic-bezier(0.600, -0.280, 0.735, 0.045)';
export const EASE_OUT_QUAD = 'cubic-bezier(0.250, 0.460, 0.450, 0.940)';
export const EASE_OUT_CUBIC = 'cubic-bezier(0.215, 0.610, 0.355, 1.000)';
export const EASE_OUT_QUART = 'cubic-bezier(0.165, 0.840, 0.440, 1.000)';
export const EASE_OUT_QUINT = 'cubic-bezier(0.230, 1.000, 0.320, 1.000)';
export const EASE_OUT_SINE = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)';
export const EASE_OUT_EXPO = 'cubic-bezier(0.190, 1.000, 0.220, 1.000)';
export const EASE_OUT_CIRC = 'cubic-bezier(0.075, 0.820, 0.165, 1.000)';
export const EASE_OUT_BACK = 'cubic-bezier(0.175, 0.885, 0.320, 1.275)';
export const EASE_IN_OUT_QUAD = 'cubic-bezier(0.455, 0.030, 0.515, 0.955)';
export const EASE_IN_OUT_CUBIC = 'cubic-bezier(0.645, 0.045, 0.355, 1.000)';
export const EASE_IN_OUT_QUART = 'cubic-bezier(0.770, 0.000, 0.175, 1.000)';
export const EASE_IN_OUT_QUINT = 'cubic-bezier(0.860, 0.000, 0.070, 1.000)';
export const EASE_IN_OUT_SINE = 'cubic-bezier(0.445, 0.050, 0.550, 0.950)';
export const EASE_IN_OUT_EXPO = 'cubic-bezier(1.000, 0.000, 0.000, 1.000)';
export const EASE_IN_OUT_CIRC = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)';
export const EASE_IN_OUT_BACK = 'cubic-bezier(0.680, -0.550, 0.265, 1.550)';
