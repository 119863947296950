import { Location, Router } from '@reach/router';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Grid from 'styled-components-grid';

import GlobalStyle from '../styles/GlobalStyle';
import { GRID_COLUMNS } from '../styles/Variables';

const theme = {
  breakpoints: {
    xs: 0,
    xsm: 400,
    sm: 576,
    md: 768,
    lg: 1023,
    xl: 1200,
  },
};

const StyledLayout = styled.div`
  min-height: 100vh;
`;

export default ({ element }) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    require('smooth-scroll')('a[href*="#"]');
  }
  return (
    <StyledLayout>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <Grid>
          <Grid.Unit size={12 / GRID_COLUMNS}>{element}</Grid.Unit>
        </Grid>
      </ThemeProvider>
    </StyledLayout>
  );
};
