import { createGlobalStyle } from 'styled-components';
import BrandonBlack from '../assets/fonts/BrandonText-Black.otf';
import BrandonBold from '../assets/fonts/BrandonText-Bold.otf';
import BrandonMedium from '../assets/fonts/BrandonText-Medium.otf';
import BrandonRegular from '../assets/fonts/BrandonText-Regular.otf';

import {
  COLOR_BLACK,
  COLOR_GREY,
  COLOR_WHITE,
  FONT_SIZE_LARGE,
  FONT_SIZE_MEDIUM,
  FONT_SIZE_SMALL,
  FONT_SIZE_XSMALL,
  FONT_SIZE_XXXSMALL,
  FONT_SIZE_XXXXLARGE,
  LAYOUT_MAX_WIDTH,
} from './Variables';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Brandon;
    src: url('${BrandonRegular}') format('opentype');
    font-weight: 300;
  }

  @font-face {
    font-family: Brandon;
    src: url('${BrandonMedium}') format('opentype');
    font-weight: 500;
  }

  @font-face {
    font-family: Brandon;
    src: url('${BrandonBold}') format('opentype');
    font-weight: 700;
  }

  @font-face {
    font-family: Brandon;
    src: url('${BrandonBlack}') format('opentype');
    font-weight: 900;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body {
    margin: 0;
    font-size: 10px;
    background-color: ${COLOR_BLACK};
    color: ${COLOR_WHITE};
    font-family: Brandon, sans-serif;
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  section {
    max-width: ${LAYOUT_MAX_WIDTH};
    margin: 0 auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h2 {
    font-size: ${FONT_SIZE_XXXXLARGE};
  }

  h3 {
    font-size: ${FONT_SIZE_LARGE};
    line-height: 1.3;

    @media (min-width: 1023px) {
      line-height: 0.75;
    }
  }

  h4 {
    font-size: ${FONT_SIZE_MEDIUM};
    line-height: 1.18;
  }

  h5 {
    font-size: ${FONT_SIZE_SMALL};
    line-height: 1.2;

    @media (max-width: 576px) {
      font-size: ${FONT_SIZE_XSMALL};
    }
  }

  h6 {
    font-size: ${FONT_SIZE_XXXSMALL};
    font-weight: 300;
    line-height: 0.8;
    color: ${COLOR_GREY};
    text-transform: uppercase;
  }

  p {
    margin: 0;
    font-size: ${FONT_SIZE_XSMALL};
  }

  a {
    text-decoration: none;
    color: ${COLOR_WHITE};
  }

  button {
    background: none;
    border: none;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  button.active.focus, button.active:focus,
  button.focus, button:active.focus, 
  button:active:focus, button:focus {
    outline: none;
    box-shadow: none;
  }

  .swiper-container .swiper-slide {
    height: auto;
  }

  .custom-cursor {
    cursor: url('../../assets/img/svg/cursor.png'), auto;
  }
`;

export default GlobalStyle;
